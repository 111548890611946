export const ServiceData = [
  {
    icon: "fas fa-globe",
    title: "External Penetration Test",
    para: "Assesses Customer's Internet accessible systems, services, and applications for security weaknesses that can be used by attackers to perform malicious actions. KSO Solutions will attempt to authenticate to publicly accessible Customer portals using password guessing attacks within the defined scope.",
  },
  {
    icon: "fas fa-server",
    title: "Internal Penetration Test",
    para: "Assesses the susceptibility of Customer's internal environment to an unprivileged user, or an attacker who gains access to Customer's environment. Aims to identify misconfigurations and vulnerabilities that can be used by attackers to move laterally and escalate privileges to accomplish a set of pre-defined goals.",
  },
  {
    icon: "fab fa-react",
    title: "Web Application Assessment",
    para: "Aims to identify security weaknesses in Customer web application(s) through external testing. Testing is performed from the perspective of an unauthenticated, and authenticated user at varying roles as appropriate to the application.",
  },
  {
    icon: "far fa-file-code",
    title: "API Assessment",
    para: "Aims to identify security weaknesses in Customer Application Programming Interfaces (APIs) through external testing. Testing is performed from the perspective of an unauthenticated, and authenticated user at varying roles as appropriate to the application.",
  },
  {
    icon: "fas fa-fish",
    title: "Social Engineering Assessment",
    para: "Evaluates employee security awareness, policies, procedures, and technical controls intended to stop attackers from obtaining access to Customer networks. Attack vectors can include emails, phone calls, Short Messaging Service (SMS) to social engineer Customer personnel to complete target objectives.",
  },
  {
    icon: "fas fa-user-secret",
    title: "Ransomware Readiness Assessment",
    para: "Assesses the Customer's internal network to highlight strengths and weaknesses of Customer's security controls as it relates to ransomware behavior. Simulates the capabilities of ransomware actors and the worming and encryption capabilities of ransomware in Customer's environment.",
  },
  {
    icon: "fas fa-laptop-code",
    title: "Thick Client Assessment",
    para: "Aims to identify security weaknesses in a Customer's desktop application and associated systems like backend databases and APIs. Testing is performed from the perspective of an unauthenticated, and authenticated user at varying roles as appropriate to the application.",
  },
  {
    icon: "fas fa-bug",
    title: "Internal Network Vulnerability Assessment",
    para: "Aims to identify known vulnerabilities using commercial vulnerability scanning tools. KSO Solutions manually verifies findings identified by scanning tools to eliminate false positives from the results.",
  },
  {
    icon: "fas fa-network-wired",
    title: "Network Segmentation Assessment",
    para: "Assesses the communication capabilities between less secure networks and sensitive networks such as those containing sensitive data like card holder data (CHD). Tests both ingress and egress to/from a sensitive network to a less secure network to identify misconfigurations.",
  },
];
