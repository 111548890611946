import React from 'react';
import './Banner.css'
const Banner = () => {
    return (
      <div className="main-banner">
        <div className="banner-bg">
          <div className="bg-overlay">
            <h1>Welcome</h1>
            <p>
              KSO Solutions offers world class penetration testing at a fraction of the price.
            </p>
            <div className="btn-flex">
                <a href="#ourservices" className="btn-royalblue">Learn More About Our Services</a>
                <a href="#contactus" className="white-btn">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Banner;
