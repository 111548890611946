import React from "react";
import "./About.css";
import AboutImg from "../assets/img/about.webp";
import Sectionheading from "./SectionHeading";
const About = () => {
  return (
    <div id="aboutus" className="about-main pb-5">
      <div className="main-width">
        <Sectionheading
          titleBefore="Our"
          titleAfter="Company"
          para="KSO Solutions is a veteran owned cyber security consultancy that specializes in offensive cyber engagements. We are a small group of engineers that work closely with our clients to identify vulnerabilities and misconfigurations and provide detailed recommendations to improve the overall security of their business."
        />
        <div className="block-data">
          <div className="flex-block">
            <div className="about-inner">
              <img className="about-img" src={AboutImg} alt="" />
            </div>
            <div className="about-inner">
              <h3 className="about-head">About Us</h3>
              <p className="para-mar">
                KSO Solutions is a boutique cybersecurity firm that provides high quality penetration testing services to its clients from US-Based consultants with experience from the world's leading cybersecurity companies at a fraction of the price.
              </p>
              <p className="para-mar">
                KSO Solutions consultants have delivered hundreds of penetration tests for Fortune 500 companies while working at the largest cybersecurity consulting firms in the world. KSO Solutions consultants currently lead Red Team Operations, or are senior members of Red Teams for multiple Fortune 500 companies.
              </p>
              <a href="#contactus" className="link-btn btn-royalblue">Contact Us To Learn More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
