import React from "react";
import Sectionheading from "./SectionHeading2";
import './Team.css'

import { TeamData } from "../Data/TeamData";
const Team = () => {
  return (
    <div id="ourteam" className="team-main mb-5">
      <div className="main-width">
        <Sectionheading
          titleBefore="Our"
          titleAfter="Team"
          para="Our consultants have a wide breadth of experience across Offensive Red Team operations as well as Blue Team operations, and software development. Our consultants have experience delivering penetration tests to Fortune 500 companies while working at the world's best cybersecurity companies."
          para2="Some of the certifications our consultants hold are as follows:"
          para3="CISSP, OSCP, OSEP, GPEN, GWAPT, GXPN, GCPN, OSWA, OSWE, GREM, OSMR, GCIH, GCNA, GCFA, GCTI"
        />
        <div className="team-area">
          <div className="team-flex">
            {TeamData.map((item, key) => (
              <div key={key} className="team-member">
                <div className="img">
                  <img src={item.img} alt="" />
                </div>
                <h3 className="titleName text-left mt-4">{item.title}</h3>
                <p className="team-desc text-left">{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
