import './App.css';
import Home from './pages/Home';
import './assets/css/style.css'
function App() {
  return <div className="App">
    <Home/>
  </div>;
}

export default App;
