import Team1 from "../assets/img/team1.png";
import Team2 from "../assets/img/team2.png";
import Jon from "../assets/img/Jon.png";
export const TeamData = [
  {
    img: Jon,
    title: "Jon Milkins",
    desc: "Jon is the Founder and Lead Consultant for KSO Solutions. He has a wide range of experience in cybersecurity from traditional SOC work, detection engineering, security research, and offensive security. He is currently working as the Red Team Operations Service Line lead for a Fortune 20 company performing all manner of penetration tests and black box red team assessments.",
  },
  {
    img: Team1,
    title: "Chuck G",
    desc: "Chuck is the CPO, or Chief Phishing Officer, for KSO Solutions. He has a passion for all things social engineering, and physical security. He currently works as a Lead Red Team Operator for a Fortune 20 company performing all manner of penetration tests and black box red team assessments.",
  },
  {
    img: Team1,
    title: "Sam C",
    desc: "Sam's experience is similar to Jon's in that it spans both offensive and defensive roles. He currently works as the Penetration Testing Lead at a Fortune 500 company. He is the cloud penetration testing expert for KSO Solutions and is a SANS TA for the SEC588 Cloud Penetration Testing and SANS560 Enterprise Penetration Testing courses.",
  },
  {
    img: Team1,
    title: "Nick M",
    desc: "Nick currently works as a Senior Penetration Tester for a Fortune 500 company after being a Senior Red Team Consultant for several years delivering all types of penetration tests and black box red team assessments for Fortune 500 companies.",
  },
];
